<template>
  <v-form class="my-4">
    <v-row>
      <!-- <v-col cols="12">
            <v-text-field v-model="username" :value="this.username" label="Username" dense outlined hide-details>
            </v-text-field>
          </v-col> -->
      <v-col cols="12">
        <v-text-field v-model="firstname" :value="this.firstname" label="First Name" dense outlined hide-details>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="lastname" :value="this.lastname" label="Last Name" dense outlined hide-details>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="address" :value="this.address" label="Adress" dense outlined hide-details>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="email" :value="this.email" label="E-mail" dense outlined hide-details>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="phonenumber" :value="this.phonenumber" label="Phone number" dense outlined hide-details>
        </v-text-field>
      </v-col>


      <!-- <v-col cols="12">
            <v-text-field v-model="birthdate" :value="this.birthdate" dense outlined hide-details label="birthdate">
            </v-text-field>
          </v-col> -->

      <!-- <v-col cols="12">
            <v-text-field v-model="status" :value="this.accountData.is_activated" dense outlined hide-details label="Status">
            </v-text-field>
          </v-col> -->

      <v-col cols="12">
        <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
          Unsave
        </v-btn>
        <v-btn color="primary" class="mt-4" :loading="loading ? true : false"
          @click.prevent="updateBasicUserInformations">
          Validate update
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  data() {
    return {
      loading: false,
      user: {},
      firstname: this.firstname,
      lastname: this.lastname,
      username: this.username,
      // birthdate: 'this.birthdate',
      address: this.address,
      phonenumber: this.phonenumber,
      email: this.email
    }
  },
  methods: {
    updateBasicUserInformations() {

      this.$emit('updateBasicInfos', {
        'user': {
          'firstname': this.firstname,
          'lastname': this.lastname,
          'email': this.email,
          'phonenumber': this.phonenumber,
          'password': this.password,
          'address': this.address,
          // 'birthdate': this.birthdate
        }
      })

      this.loading = true;
      var bodyFormData = {
        user: {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phonenumber: this.phonenumber,
          password: this.password,
          username: this.username,
          address: this.address,
          // birthdate: this.birthdate
        }
      }
      this.loading = true;
      Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
        .data(bodyFormData)
        .raw((response) => {
          switch (response.success) {
            case true:
              this.loading = false;
              alert(response.detail)
              this.loading = false;
              this.$store.commit("setUser", {
                id: response.user.id,
                email: response.user.email,
                firstname: response.user.firstname,
                lastname: response.user.lastname,
                phonenumber: response.user.phonenumber,
                username: response.user.username,
                // birthdate: response.user.birthdate,
                address: response.user.address,
                lastlogin: response.user.last_login,
              });
              // this.$router.push({ name: 'user.account' });
              break;
            default:
              alert(response.values.log)
              this.loading = false;
              break;
          }
        }).catch((err) => {
          window.console.log(err)
          this.loading = false;
        });
    },

    resetForm() {
      this.firstname = this.user.firstname
      this.lastname = this.user.lastname
      this.username = this.user.username
      // this.birthdate = this.user.birthdate
      this.address = this.user.address
      this.phonenumber = this.user.phonenumber
      this.email = this.user.email
    }
  },
  beforeMount() {
    Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        // this.id = response.listEntity[0].id,
        this.email = response.listEntity[0].email,
          this.firstname = response.listEntity[0].firstname,
          this.lastname = response.listEntity[0].lastname,
          this.phonenumber = response.listEntity[0].phonenumber,
          this.username = response.listEntity[0].username,
          // birthdate = response.listEntity[0].birthdate,
          this.address = response.listEntity[0].address,
          this.lastlogin = response.listEntity[0].last_login,
          this.user = response.listEntity[0]
      }
    }).catch((err) => { });
  },
  setup(props) {
    // const status = ['Active', 'Inactive', 'Pending', 'Closed']
    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
    }
    return {
      icons
    }
  },
}
</script>
